import * as THREE from "three";
import Experience from "./Experience";
import MovementParticle from "./MovementParticle";

export default class MovementParticles {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera.instance; // Accéder à la caméra principale
    this.clock = new THREE.Clock();
    this.movementsParticles = [];

    this.life = Math.random() * 2;
    this.experience.world.particleGroup = new THREE.Group();
    this.experience.world.movementsParticles = this;

    this.setTexture();
    this.init();
  }

  init() {
    this.scene.add(this.experience.world.particleGroup);
    for (let index = 0; index < 40; index++) {
      this.movementsParticles.push(new MovementParticle(this.texture));
    }
  }

  setTexture() {
    this.texture = new THREE.MeshBasicMaterial({ color: "white" });
  }

  update() {
    const cameraDirection = new THREE.Vector3();
    this.camera.getWorldDirection(cameraDirection);
    this.desiredPosition = new THREE.Vector3();
    this.desiredPosition
      .copy(this.camera.position)
      .add(cameraDirection.multiplyScalar(-5));
    this.movementsParticles?.forEach((el) => el?.update());
  }
}
