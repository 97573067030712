const GET_testing = async () => {
  return await fetch(`${import.meta.env.VITE_PORT_BACK}testing`);
};

const POST_testing = async (pseudo) => {
  return await fetch(`${import.meta.env.VITE_PORT_BACK}star`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ pseudo }),
  });
};

const GET_RetrieveAllStars = async () => {
  return await fetch(`${import.meta.env.VITE_PORT_BACK}stars`).then((res) =>
    res.json()
  );
};

export { GET_testing, POST_testing, GET_RetrieveAllStars };
