const texts = {
  dotas: {
    section: {
      title: {
        connected: "Contenus débloqués",
        disconnected: "Créé ton étoile !",
      },
    },
  },
};

export default texts;
