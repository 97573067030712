import gsap from "gsap";
import { GET_RetrieveAllStars, GET_testing, POST_testing } from "../services";
import Experience from "./Experience";
import MyStar from "./World/MyStar";
import { CountUp } from "countup.js";
import * as THREE from "three";
import { serializer } from "./Utils/Debug";
import DotasPanel from "./DotasPanel";

export default class UI {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;

    this.init();
  }

  init() {
    this.initDOM();
    this.initEventListeners();
    this.initStarCTA();
  }

  initDOM() {
    this.CTA__GetTesting = document.getElementById("cta__GET_testing");
    this.CTA__PostTesting = document.getElementById("cta__POST_testing");
    this.CTA__GETAllStars = document.getElementById("cta__GET_allStars");
    this.CONTAINER__RIGHT_PANEL = document.querySelector(
      ".right__panel-container"
    );
    this.RightPanel_CreateStarContainer = document.querySelector(
      ".right__panel-create_star"
    );
    this.RightPanel_DotaContainer =
      document.querySelector(".right__panel-dota");
    this.CTA__AddStar = document.querySelector(".CTA__add-star");
    this.CTA__AddStarContainer = document.querySelector(
      ".CTA__add-star-container"
    );
    this.hidder = document.querySelector(".hidder");

    this.objectives__current_stars_number = document.getElementById(
      "current_stars--number"
    );
    this.objectives__current_stars_pallier = document.getElementById(
      "current_stars--pallier"
    );
    this.objectifs__cadeauxList = document.getElementById(
      "objectifs__cadeaux-liste"
    );

    this.objectifs__dropdownContainer = document.querySelector(
      ".objectifs__dropdown-container"
    );

    this.objectifs__dropdownTitle = document.querySelector(
      ".objectifs__dropdown-container > p"
    );
    this.RightPanel_Closer = document.querySelector(".right__panel--closer");
    this.FindStarsContainer = document.querySelector(".find-stars__container");
    this.FindStars__Main = document.querySelector(".find-stars__main");
    this.FindStars__Mine = document.querySelector(".find-stars__mine");

    this.Dota__presentationPanel_container = document.querySelector(
      ".dota__presentation-panel_container"
    );

    this.Dota__presentationPanelCloser = document.querySelector(
      ".dota__presentation-panel_closer"
    );

    this.objectifsBarre = document.querySelector(".objectif_barre--content");
  }

  async handleDotaGeter(id) {
    const serialize = (richText, serializers) => {
      return richText
        .map((node) => {
          const type = serializers[node.type];
          if (type) {
            return type({ node, children: node.text || "" });
          }
          return node.text || "";
        })
        .join("");
    };

    const dota = await this.experience.client.getByUID("dota", id);
    this.RightPanel_DotaContainer.style.display = "flex";
    this.RightPanel_DotaContainer.innerHTML = serialize(
      dota.data.rich_text,
      serializer
    );

    if (dota.data.yt_embed_id) {
      this.experience.ui.RightPanel_DotaContainer.innerHTML += `
    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${dota.data.yt_embed_id}?si=-oZK0DH3ecRTJYiF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
    }

    if (dota.data?.external_link?.url) {
      this.experience.ui.RightPanel_DotaContainer.innerHTML += `
        <a class="cta" href="${dota.data.external_link.url}" target="_blank">${dota.data.external_link_text}</a>
      `;
    }

    document.querySelector(".right__panel-dota").scrollTo(0, 0);

    gsap.to(this.CONTAINER__RIGHT_PANEL, {
      x: "0",
    });
    this.showHidder();
  }

  initCadeauxDropdown() {
    // Init DOM
    const userLS = window.localStorage.getItem("user");

    this.experience.ui.dotas.forEach((el) => {
      let active = true;
      if (el.nb_inscrits > this.experience.world.stars.countNumber) {
        active = false;
      }

      console.log(el.linked_ui);
      // this.handleDotaGeter(el.linked_ui);

      console.log(el);
      // this.objectifs__cadeauxList.innerHTML += `<li data-id="${
      //   el.linked_ui
      // }" class="${active === false || userLS === null ? "inactive" : ""}">${
      //   el.nom[0].text
      // }</li>`;
    });

    // Init Event Listener element
    // this.objectifs__cadeauxList.querySelectorAll("li").forEach((el) => {
    //   el.addEventListener("click", () => {
    //     if (!el.classList.contains("inactive")) {
    //       this.handleDotaGeter(el.dataset.id);
    //     }
    //   });
    // });
  }

  showDOM(element, isFlex) {
    if (isFlex === undefined) {
      isFlex = false;
    }

    if (isFlex) {
      element.style.display = "flex";
    } else {
      element.style.display = "initial";
    }
  }

  hideDOM(element) {
    element.style.display = "none";
  }

  closeRightPanel() {
    this.RightPanel_DotaContainer.style.display = "none";
    this.RightPanel_CreateStarContainer.style.display = "none";
  }

  initStarCTA() {
    this.hideDOM(this.CTA__AddStar);
    this.hideDOM(this.CTA__AddStarContainer);

    const userLS = window.localStorage.getItem("user");

    if (userLS === null) {
      this.showDOM(this.CTA__AddStar);
      this.showDOM(this.CTA__AddStarContainer, true);
    } else {
      this.FindStarsContainer.style.display = "flex";
    }
  }

  initEventListeners() {
    this.CTA__GetTesting?.addEventListener("click", this.GETTesting);
    this.CTA__PostTesting?.addEventListener("click", this.POSTTesting);
    this.CTA__GETAllStars?.addEventListener("click", () =>
      this.GET_RetrieveAllStars()
    );
    this.CTA__AddStar?.addEventListener("click", () => this.initForm(this));
    this.hidder?.addEventListener("click", () => {
      this.hideHidder();
      gsap.to(this.CONTAINER__RIGHT_PANEL, {
        x: "100%",
      });
      this.closeRightPanel();
    });
    this.RightPanel_Closer.addEventListener("click", () => {
      this.hideHidder();
      gsap.to(this.CONTAINER__RIGHT_PANEL, {
        x: "100%",
      });
      this.closeRightPanel(this);
    });
    this.FindStars__Main?.addEventListener("click", () => {
      const camera = this.experience.camera.instance;
      const mainStarPosition = new THREE.Vector3(
        this.experience.world.mainStar.position.x,
        this.experience.world.mainStar.position.y,
        this.experience.world.mainStar.position.z
      );

      // Position initiale et finale de la caméra
      const targetCameraPosition = new THREE.Vector3(
        mainStarPosition.x > 0
          ? mainStarPosition.x + 10
          : mainStarPosition.x - 10,
        mainStarPosition.y > 0
          ? mainStarPosition.y + 10
          : mainStarPosition.y - 10,
        mainStarPosition.z > 0
          ? mainStarPosition.z + 10
          : mainStarPosition.z - 10
      );

      // Position cible pour le lookAt (initialement la même que la caméra regarde)
      const currentLookAt = new THREE.Vector3();
      camera.getWorldDirection(currentLookAt);
      currentLookAt.add(camera.position);

      gsap.to(camera.position, {
        x: targetCameraPosition.x,
        y: targetCameraPosition.y,
        z: targetCameraPosition.z,
        duration: 2,
        onUpdate: () => {
          // Interpoler le lookAt vers la cible
          currentLookAt.lerp(mainStarPosition, 0.03);

          // Appliquer lookAt à la caméra
          camera.lookAt(currentLookAt);
        },
      });
    });

    this.FindStars__Mine?.addEventListener("click", () => {
      const camera = this.experience.camera.instance;
      const myStarPosition = new THREE.Vector3(
        this.experience.myStar.myStarParticle.position.x,
        this.experience.myStar.myStarParticle.position.y,
        this.experience.myStar.myStarParticle.position.z
      );

      // Position initiale et finale de la caméra
      const targetCameraPosition = new THREE.Vector3(
        myStarPosition.x > 0 ? myStarPosition.x + 10 : myStarPosition.x - 10,
        myStarPosition.y > 0 ? myStarPosition.y + 10 : myStarPosition.y - 10,
        myStarPosition.z > 0 ? myStarPosition.z + 10 : myStarPosition.z - 10
      );

      // Position cible pour le lookAt (initialement la même que la caméra regarde)
      const currentLookAt = new THREE.Vector3();

      gsap.to(camera.position, {
        x: targetCameraPosition.x,
        y: targetCameraPosition.y,
        z: targetCameraPosition.z,
        duration: 2,
        onUpdate: () => {
          // Interpoler le lookAt vers la cible
          currentLookAt.lerp(myStarPosition, 0.3);

          // Appliquer lookAt à la caméra
          camera.lookAt(currentLookAt);
        },
      });
    });

    this.Dota__presentationPanelCloser?.addEventListener("click", () => {
      gsap.to(this.Dota__presentationPanel_container, {
        opacity: 0,
        x: "100%",
        display: "none",
      });
    });

    this.objectifsBarre?.addEventListener("click", () => {
      window.digitalData.page.pageInfo.pageName =
        "Leman:Toutes les etoiles:Open Jauge";
      document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
      gsap.to(this.Dota__presentationPanel_container, {
        opacity: 1,
        x: 0,
        display: "flex",
      });
    });
  }

  showHidder() {
    this.hidder.classList.add("active");
  }

  hideHidder() {
    this.hidder.classList.remove("active");
  }

  glitchSequence() {
    const activeGlitchPass = () => {
      _this.experience.renderer.glitchPass.enabled = true;
    };

    const unactiveGlithPass = () => {
      _this.experience.renderer.glitchPass.enabled = false;
    };

    activeGlitchPass();
    setTimeout(() => unactiveGlithPass(), 300);
    setTimeout(() => activeGlitchPass(), 500);
    setTimeout(() => unactiveGlithPass(), 700);
    setTimeout(() => activeGlitchPass(), 1300);
    setTimeout(() => unactiveGlithPass(), 1600);
    setTimeout(() => activeGlitchPass(), 1800);
    setTimeout(() => unactiveGlithPass(), 2000);
  }

  initForm(_this) {
    window.digitalData.page.pageInfo.pageName =
      "Leman:Toutes les etoiles:Init Form";
    document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
    _this.RightPanel_CreateStarContainer.style.display = "block";
    _this.showHidder();
    gsap.to(_this.CONTAINER__RIGHT_PANEL, {
      x: 0,
    });
    var connect_button = new WMGConnect("", {
      opt_in_thank_you_enabled: false,
      opt_in_form_target: "#formulaire",
    });

    connect_button.setCallback(function (connect_data) {
      gsap.to(_this.CONTAINER__RIGHT_PANEL, { x: "100%" });
      _this.hideDOM(_this.CTA__AddStar);
      _this.closeRightPanel();

      window.digitalData.page.pageInfo.pageName =
        "Leman:Toutes les etoiles:Callback Form";
      document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
      gsap.to(_this.CTA__AddStarContainer, {
        display: "none",
        opacity: 0,
        y: 30,
      });
      // _this.showDOM(_this.objectifs__dropdownContainer);
      _this.hideHidder();
      _this.initMyStar(connect_data.user.additionalData.form_data.Etoile);
      _this.POSTTesting(connect_data.user.additionalData.form_data.Etoile);

      _this.glitchSequence();
    });
  }

  getElementByNbInscrits(data, x) {
    for (const element of data) {
      if (element.nb_inscrits >= x) {
        return element;
      }
    }
    return null; // Aucun élément trouvé
  }

  async updateBar() {
    // this.objectives__current_stars_number.innerHTML =
    //   this.experience.world.stars.countNumber;
    const dota_association = this.getElementByNbInscrits(
      this.dotas,
      this.experience.world.stars.countNumber
    );

    this.currentDota = await this.experience.client.getByUID(
      "dota",
      dota_association.linked_ui
    );

    // this.objectives__current_stars_pallier.innerHTML =
    //   dota_association.nb_inscrits;

    // gsap.to(".objectif_barre--inner", {
    //   width: `calc(${
    //     (this.experience.world.stars.countNumber /
    //       dota_association.nb_inscrits) *
    //     100
    //   }% - 10px)`,
    //   duration: 1,
    // });
  }

  async initDotaBar() {
    const countUp = new CountUp(
      "current_stars--number",
      this.experience.world.stars.countNumber
    );

    // this.objectifs__dropdownContainer.style.display = "block";

    // Init Event Listener opener
    // this.objectifs__dropdownTitle.addEventListener("click", () => {
    //   alert("test");
    //   this.experience.world.mainStar.sound.pause();
    //   this.experience.world.mainStar.sound.play();
    //   // if (this.objectifs__dropdownContainer.classList.contains("active")) {
    //   //   this.objectifs__dropdownContainer.classList.remove("active");
    //   // } else {
    //   //   this.objectifs__dropdownContainer.classList.add("active");
    //   // }
    // });

    this.dotasPanel = new DotasPanel();

    this.initCadeauxDropdown();

    // this.updateBar();
  }

  initMyStar(pseudo) {
    this.experience.myStar = new MyStar(true, pseudo);

    this.dotasPanel.initUnlockedEventListeners();

    document
      .querySelector(".dota__presentation-unlocked_dota")
      .classList.remove("disconnected");

    document
      .querySelector(".dota__presentation-unlocked_dota")
      .classList.add("connected");

    document.querySelector(".find-stars__container").style.display = "flex";
  }

  GETTesting() {
    const response = GET_testing();
    response
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  POSTTesting(pseudo) {
    const response = POST_testing(pseudo + "--" + Date.now());
    response
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  async GET_RetrieveAllStars() {
    const response = await GET_RetrieveAllStars();

    return response;
  }
}
