export default [
  {
    name: "environmentMapTexture",
    type: "cubeTexture",
    path: [
      "textures/environmentMap/space/px.png",
      "textures/environmentMap/space/nx.png",
      "textures/environmentMap/space/py.png",
      "textures/environmentMap/space/ny.png",
      "textures/environmentMap/space/pz.png",
      "textures/environmentMap/space/nz.png",
    ],
  },
  {
    name: "starLens1",
    type: "texture",
    path: "textures/stars/lensflare0.png",
  },
  {
    name: "starLens2",
    type: "texture",
    path: "textures/stars/lensflare2.png",
  },
  {
    name: "starLens2",
    type: "texture",
    path: "textures/stars/lensflare3.png",
  },
  {
    name: "prodMain",
    type: "audioSound",
    path: "sounds/da-main.mp3",
  },
];
