import * as THREE from "three";
import Experience from "./Experience";

export default class MovementParticle {
  constructor(texture) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera.instance; // Accéder à la caméra principale
    this.clock = new THREE.Clock();
    this.texture = texture;

    this.life = Math.random() * 2;
    this.init();
  }

  init() {
    this.life = 1;
    this.setGeometry();
    // this.setTexture();
    this.setMesh();
  }

  setGeometry() {
    this.geometry = new THREE.BoxGeometry(0.002, 0.002, 0.4, 4, 4, 4);
  }

  setMesh() {
    this.mesh = new THREE.Mesh(this.geometry, this.texture);

    // Récupérer la position actuelle de la caméra
    const cameraDirection = new THREE.Vector3();
    this.camera.getWorldDirection(cameraDirection);

    this.mesh.position
      .copy(this.camera.position)
      .add(cameraDirection.multiplyScalar(4));

    this.mesh.position.x += (Math.random() - 0.5) * 3;
    this.mesh.position.y += (Math.random() - 0.5) * 3;
    this.mesh.position.z += (Math.random() - 0.5) * 3;

    this.experience.world.particleGroup.add(this.mesh);
    // Placer `MovementParticles` un peu devant la caméra
  }

  reset() {
    const cameraDirection = new THREE.Vector3();
    this.camera.getWorldDirection(cameraDirection);

    this.mesh.position
      .copy(this.camera.position)
      .add(cameraDirection.multiplyScalar(5));

    this.mesh.position.x += (Math.random() - 0.5) * 3;
    this.mesh.position.y += (Math.random() - 0.5) * 3;
    this.mesh.position.z += (Math.random() - 0.5) * 3;

    this.mesh.visible = true;
  }

  update() {
    if (this.life <= 0) {
      this.mesh.visible = false;
      this.reset();
      this.life = Math.random() * 2;
    } else {
      // Récupérer la position actuelle de la caméra

      if (this.experience.world.movementsParticles.desiredPosition) {
        this.mesh.position.lerp(
          this.experience.world.movementsParticles.desiredPosition,
          0.04
        );

        this.mesh.lookAt(
          this.experience.world.movementsParticles.desiredPosition
        );
      }

      this.life -= this.clock.getDelta();
    }
  }
}
