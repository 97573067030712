import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { saveAs } from "file-saver";

export default class MyStar {
  constructor(isFirstTime, pseudo) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera.instance;
    this.time = this.experience.time;
    this.debug = this.experience.debug;
    this.canvas = this.experience.canvas;

    this.isFirstTime = isFirstTime;
    this.pseudo = pseudo;

    this.starCallbacks = []; // Stockage des callbacks pour chaque étoile
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("fox");
    }

    // Resource
    this.starTexture1 = this.resources.items.starLens1;
    this.starTexture2 = this.resources.items.starLens2;

    this.setMaterial();
    this.setModel();
    this.setMesh();
    this.setupEvents(); // Gérer les clics
    this.updateCount();
    this.createLabel();
  }

  async updateCount() {
    this.experience.world.stars.countNumber += 1;

    this.experience.ui.updateBar();
    // this.experience.ui.objectifs__cadeauxList.innerHTML = "";
    this.experience.ui.initCadeauxDropdown();
  }

  setupEvents() {
    window.addEventListener("pointerdown", (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.experience.camera.instance);

      const intersects = this.raycaster.intersectObject(this.myStarParticle);
      if (intersects.length > 0) {
        const intersect = intersects[0];
        const index = intersect.index;

        if (index !== undefined) {
          // Animer la taille de l'étoile cliquée
          const scales = this.paricleGeometry.attributes.scale.array;
          gsap.to(scales, {
            [index]: scales[index] * 2, // Doubler la taille
            duration: 1,
            onUpdate: () => {
              this.paricleGeometry.attributes.scale.needsUpdate = true;
            },
            onComplete: () => {
              // Réduire à la taille initiale
              gsap.to(scales, {
                [index]: scales[index] / 2,
                duration: 1,
                onUpdate: () => {
                  this.paricleGeometry.attributes.scale.needsUpdate = true;
                },
              });
            },
          });
        }
      }
    });
  }

  setMaterial() {
    this.particleMaterial = new THREE.ShaderMaterial({
      vertexShader: `
            uniform float uTime;
            attribute float scale;
            attribute float timeOffset;
            attribute float noiseOffset; // Nouvel attribut pour le bruit
            varying vec3 vColor;

            // Fonction pour générer un bruit pseudo-aléatoire
            float random(vec3 point) {
                return fract(sin(dot(point, vec3(12.9898, 78.233, 45.164))) * 43758.5453);
            }

            void main() {

                // Calcul du bruit avec la position
                float noise = random(position + vec3(noiseOffset)); // Bruit basé sur la position et le bruit offset

                // Calcul de la pulsation avec bruit
                float pulsate = max(0.4, sin(uTime * 1.0 + timeOffset + noise * 2.0)) * 1.0 + 1.0;

                vec4 modelPosition = modelViewMatrix * vec4(position, 1.0);
                gl_PointSize = (pulsate * scale * 100.0) / -modelPosition.z;

                modelPosition.x += 0.5 * cos(uTime);
                modelPosition.y += sin(uTime);
                // modelPosition.z += sin(uTime);

                gl_Position = projectionMatrix * modelPosition;
                vColor = color;
            }
        `,
      fragmentShader: `
            uniform sampler2D uTexture;  // La texture
            uniform float uOpacity;
            varying vec3 vColor;  // Couleur de la particule calculée dans le vertex shader

            void main() {
              // Light point: Calculer l'intensité lumineuse en fonction de la distance
              float strength = distance(gl_PointCoord, vec2(0.5));  // Calculer la distance à partir du centre
              strength = 1.0 - strength;  // L'intensité est plus forte au centre
              strength = pow(strength, 30.0);  // Accentuer l'intensité près du centre

              // Récupérer la couleur de la texture au point de la particule
              vec4 texColor = texture2D(uTexture, gl_PointCoord);
              if (texColor.a < 0.1) discard;  // Si la texture est trop transparente, ne pas afficher le pixel

              // Réduire l'intensité de la texture en la multipliant par un facteur
              texColor.rgb *= uOpacity * 20.0;  // Réduire la luminosité de la texture (valeur entre 0.0 et 1.0)

              // Mélanger la couleur de la particule avec la texture
              vec3 finalColor = mix(vColor, texColor.rgb, 0.3);  // Mélange à 50% entre la couleur de la particule et la texture

              // Final color avec le facteur d'intensité
              gl_FragColor = vec4(finalColor * strength, texColor.a * uOpacity);  // Appliquer la luminosité calculée à la couleur finale
              
              #include <colorspace_fragment>  // Gérer la conversion de couleur, si nécessaire
            }
        `,
      uniforms: {
        uTexture: { value: this.starTexture1 }, // Transmettre la texture
        uTime: { value: 0 },
        uOpacity: { value: 0 },
      },
      depthWrite: false,
      blending: THREE.AdditiveBlending,
      vertexColors: true,
      opacity: 0,
      transparent: true,
    });
  }

  createLabel() {
    // Créer une texture à partir d'un canvas HTML
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = 512;
    canvas.height = 128;

    context.font = "48px Arial";
    context.fillStyle = "white";
    context.textAlign = "center";
    context.fillText(this.pseudo, canvas.width / 2, canvas.height / 2);

    const texture = new THREE.CanvasTexture(canvas);
    const material = new THREE.SpriteMaterial({
      map: texture,
      transparent: true,
      opacity: 1.0, // Commencez avec le label masqué
    });
    const sprite = new THREE.Sprite(material);

    console.log(this.myStarParticle.position);
    // Positionner le sprite légèrement au-dessus de l'étoile
    sprite.position.set(
      this.myStarParticle.position.x,
      this.myStarParticle.position.y, // Ajustez l'offset en hauteur
      this.myStarParticle.position.z
    );

    sprite.scale.set(10, 2.5, 1); // Ajustez la taille du label
    this.scene.add(sprite);
  }

  setModel() {
    // Geometry
    this.paricleGeometry = new THREE.BufferGeometry();
    const count = 1;

    const insideColor = new THREE.Color("white");
    const outsideColor = new THREE.Color("blue");

    const positions = new Float32Array(count * 3); // Positions
    const colors = new Float32Array(count * 3); // Couleurs
    const scales = new Float32Array(count); // Échelles individuelles
    const timeOffsets = new Float32Array(count); // Décalages temporels

    const cameraDirection = new THREE.Vector3();
    this.camera.getWorldDirection(cameraDirection);
    this.desiredPosition = new THREE.Vector3();
    this.desiredPosition
      .copy(this.camera.position)
      .add(cameraDirection.multiplyScalar(5));

    for (let i = 0; i < count; i++) {
      // Positions aléatoires
      positions[i * 3] = 0;
      positions[i * 3 + 1] = 0;
      positions[i * 3 + 2] = 0;

      // Calculer la distance depuis le centre (0, 0, 0)
      const distanceFromCenter = Math.sqrt(
        Math.abs(Math.pow(positions[i * 3], 2)) +
          Math.abs(Math.pow(positions[i * 3 + 1], 2)) +
          Math.abs(Math.pow(positions[i * 3 + 2], 2))
      );

      // Lerp basé sur cette distance, en normalisant par rapport au rayon
      const lerpFactor =
        distanceFromCenter / this.experience.world.stars.RADIUS;
      const mixedColor = insideColor.clone();
      mixedColor.lerp(outsideColor, lerpFactor);

      colors[i * 3] = mixedColor.r;
      colors[i * 3 + 1] = mixedColor.g;
      colors[i * 3 + 2] = mixedColor.b;

      // Taille initiale
      scales[i] = 500;

      // Décalage temporel aléatoire
      timeOffsets[i] = Math.random() * Math.PI * 2; // Décalage entre 0 et 2π
    }

    this.paricleGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    this.paricleGeometry.setAttribute(
      "color",
      new THREE.BufferAttribute(colors, 3)
    );
    this.paricleGeometry.setAttribute(
      "scale",
      new THREE.BufferAttribute(scales, 1)
    );
    this.paricleGeometry.setAttribute(
      "timeOffset",
      new THREE.BufferAttribute(timeOffsets, 1)
    );
  }

  setMesh() {
    // Points
    this.myStarParticle = new THREE.Points(
      this.paricleGeometry,
      this.particleMaterial
    );
    const cameraDirection = new THREE.Vector3();
    this.camera.getWorldDirection(cameraDirection);
    this.desiredPosition = new THREE.Vector3();
    this.desiredPosition
      .copy(this.camera.position)
      .add(cameraDirection.multiplyScalar(3));

    this.myStarParticle.position.copy(this.desiredPosition);

    this.scene.add(this.myStarParticle);

    if (this.isFirstTime) {
      this.myStarParticle.scale.set(0, 0, 0);
      this.myStarParticle.transparent = true;
      this.myStarParticle.opacity = 1;

      gsap.to(this.particleMaterial.uniforms.uOpacity, {
        value: 1,
        duration: 5,
        onComplete: () => {
          gsap.to(this.myStarParticle.scale, {
            duration: 10,
            x: (Math.random() * this.experience.world.stars.RADIUS) / 2,
            y: (Math.random() * this.experience.world.stars.RADIUS) / 2,
            z: (Math.random() * this.experience.world.stars.RADIUS) / 2,
          });
        },
      });
    } else {
      this.myStarParticle.scale.set(0, 0, 0);
      this.myStarParticle.transparent = true;
      this.particleMaterial.uniforms.uOpacity.value = 1;
      this.myStarParticle.opacity = 1;
      this.myStarParticle.position.set(
        (Math.random() * this.experience.world.stars.RADIUS) / 2,
        (Math.random() * this.experience.world.stars.RADIUS) / 2,
        (Math.random() * this.experience.world.stars.RADIUS) / 2
      );
      this.myStarParticle.scale.set(
        (Math.random() * this.experience.world.stars.RADIUS) / 2,
        (Math.random() * this.experience.world.stars.RADIUS) / 2,
        (Math.random() * this.experience.world.stars.RADIUS) / 2
      );
    }
  }

  async fallbackDownload() {
    const blob = await fetch(this.dataURL).then((res) => res.blob());

    saveAs(blob, "image.jpeg");
  }

  async handleDownload() {
    if (navigator.share && window.innerWidth < 728) {
      const blob = await fetch(this.dataURL).then((res) => res.blob());
      try {
        await navigator.share({
          files: [
            new File([blob], "image.jpeg", {
              type: blob.type,
            }),
          ],
          title: "Voici mon étoile !",
          text: "Créé ton étoile pour découvrir les contenus exclusifs de la galaxie de Léman !",
        });
      } catch (error) {
        this.fallbackDownload();
      }
    } else {
      this.fallbackDownload();
    }
  }

  savePictureAndShare() {
    window.digitalData.page.pageInfo.pageName =
      "Leman:Toutes les etoiles:Share Star";
    document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
    this.dataURL = this.canvas.toDataURL("image/jpeg", 0.75);
    this.handleDownload();
  }

  onClick() {
    this.savePictureAndShare();
  }

  setupEvents() {
    window.addEventListener("pointerdown", (event) => {
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.experience.camera.instance);

      const intersects = this.raycaster.intersectObject(this.myStarParticle);

      if (intersects.length > 0) {
        const intersect = intersects[0];
        const index = intersect.index;

        this.onClick();

        if (index !== undefined) {
          // Animer la taille de l'étoile cliquée
          const scales = this.paricleGeometry.attributes.scale.array;
          gsap.to(scales, {
            [index]: scales[index] * 2, // Doubler la taille
            duration: 1,
            onUpdate: () => {
              this.paricleGeometry.attributes.scale.needsUpdate = true;
            },
            onComplete: () => {
              // Réduire à la taille initiale
              gsap.to(scales, {
                [index]: scales[index] / 2,
                duration: 1,
                onUpdate: () => {
                  this.paricleGeometry.attributes.scale.needsUpdate = true;
                },
              });
            },
          });
        }
      }
    });
  }

  update() {
    if (this.particleMaterial) {
      this.particleMaterial.uniforms.uTime.value = this.time.elapsed * 0.001;
      this.myStarParticle.rotation.y = this.time.elapsed * 0.00001;
    }
  }
}
